import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBdadPKgvLwZCVtevTanebSfmdXb9pb5Hw",
  authDomain: "businessdog-70c32.firebaseapp.com",
  databaseURL:
    "https://businessdog-70c32-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "businessdog-70c32",
  storageBucket: "businessdog-70c32.appspot.com",
  messagingSenderId: "248665261264",
  appId: "1:248665261264:web:1388de9febdc76e699cad5",
  measurementId: "G-1EPGX2K77Q",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
