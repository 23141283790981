import * as React from "react";
import styles from "./Admin.module.scss";
import { ContentPageProps } from "../ContentPage/ContentPage";
import AdminContentElement from "./AdminContentElement";
import { Box, Modal } from "@mui/material";
import { ModalStyle } from "../ContentPage/ContentElement";
import AdminNewForm from "./AdminNewForm";

export type Entry = {
  title: string;
  url: string;
  size: number;
  id: number;
  rating: string;
  createdAt: string;
  updatedAt: string;
  recommended: boolean;
  comments: {
    comment: string;
  }[];
};

function AdminContentPage({ entries, tabTitle }: ContentPageProps) {
  // new-item modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className={styles.wrapper}>
      <button onClick={handleOpen}>new Entry</button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={styles.adminModal}
      >
        <Box
          sx={{
            ...ModalStyle,
            height: "100%",
            width: "90%",
          }}
          className={"modal-container"}
        >
          <AdminNewForm
            tabTitle={tabTitle}
            currentAmountOfEntries={entries?.length || 0}
          />
        </Box>
      </Modal>
      <div className={styles.wrapper}>
        {entries.map((entry) => (
          <AdminContentElement
            entry={entry}
            tabTitle={tabTitle}
            key={entry.id + tabTitle}
          />
        ))}
      </div>
    </div>
  );
}

export default AdminContentPage;
