import { ref, getDatabase } from "firebase/database";
import { useList } from "react-firebase-hooks/database";
import { app } from "../firebase/firebase";
import { useState } from "react";
import { Entry } from "../Admin/AdminContentPage";

const database = getDatabase(app);

type UseFirebaseContent = {
  content: Record<string, Entry[]> | undefined;
};

const useFirebaseContent = (): UseFirebaseContent => {
  const [snapshots, loading] = useList(ref(database));
  const [content, setContent] = useState<Record<string, Entry[]> | undefined>();

  if (
    snapshots !== undefined &&
    snapshots.length > 0 &&
    content === undefined &&
    !loading
  ) {
    setContent(snapshots[0].val());
  }

  return {
    content,
  };
};

export default useFirebaseContent;
