import { Entry } from "./AdminContentPage";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as React from "react";
import styles from "./Admin.module.scss";
import { getDatabase, ref, set } from "firebase/database";

const AdminEditForm = ({
  passedEntry,
  tabTitle,
}: {
  passedEntry: Entry;
  tabTitle: string;
}) => {
  const [entry, setEntry] = useState<undefined | Entry>();

  useEffect(() => {
    setEntry(passedEntry);
  }, []);

  const { register, handleSubmit, setValue } = useForm();

  const onSubmit = (data: any) => {
    const toSubmit = {
      ...passedEntry,
      ...data,
      comments: data.comments.map((comm: string) => ({ comment: comm })),
    };
    const db = getDatabase();
    set(ref(db, `content/${tabTitle}/${toSubmit.id}`), toSubmit);
  };

  const onAddComment = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (!entry) return;

    const withNewComment = [...entry.comments, { comment: "" }];

    setEntry({
      ...entry,
      comments: withNewComment,
    });
  };

  const onRemoveComment = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number
  ) => {
    e.preventDefault();
    if (!entry) return;

    const withoutComment = entry.comments.filter((_, i) => i !== id);

    setValue(
      "comments",
      withoutComment.map((comment) => comment.comment)
    );

    setEntry({
      ...entry,
      comments: withoutComment,
    });
  };

  if (!entry) return null;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <h3>
        Editing {tabTitle} {entry.title}
      </h3>
      <span>title</span>
      <div className={styles.editFormDiv}>
        <input defaultValue={entry.title} {...register("title")} />
      </div>
      <span>url</span>
      <div className={styles.editFormDiv}>
        <input defaultValue={entry.url} {...register("url")} />
      </div>
      <span>size</span>
      <div className={styles.editFormDiv}>
        <input defaultValue={entry.size} {...register("size")} />
      </div>

      <span>comments</span>

      {entry.comments?.map((comment, idx) => (
        <div className={styles.editFormDiv} key={comment.comment + idx}>
          <input
            defaultValue={comment.comment}
            {...register(`comments.${idx}`)}
          />
          <button onClick={(e) => onRemoveComment(e, idx)}>remove</button>
        </div>
      ))}
      <button onClick={onAddComment}>add comment</button>

      <span>Rating</span>

      <div className={styles.editFormDiv}>
        <input defaultValue={entry.rating} {...register("rating")} />
      </div>

      <button type="submit">Update!</button>
    </form>
  );
};

export default AdminEditForm;
