import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { app } from "../firebase/firebase";
import { useState } from "react";
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    return user;
  } catch (err) {
    console.error(err);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    alert(err.message);
  }
};

interface UseFirebaseLogin {
  attemptLogin: () => void;
  isFabian: boolean;
  loading: boolean;
}

const useFirebaseLogin = (): UseFirebaseLogin => {
  const [loading, setLoading] = useState(false);
  const [isFabian, setIsFabian] = useState(false);

  const attemptLogin = () => {
    setLoading(true);
    signInWithGoogle().then((res) => {
      if (res?.email === "fabian.hemmerich@gmail.com") {
        setIsFabian(true);
      }

      setLoading(false);
    });
  };

  return {
    attemptLogin,
    isFabian,
    loading,
  };
};

export default useFirebaseLogin;
