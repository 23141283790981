import { Entry } from "./AdminContentPage";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as React from "react";
import styles from "./Admin.module.scss";
import { getDatabase, ref, set } from "firebase/database";

const AdminNewForm = ({
  tabTitle,
  currentAmountOfEntries,
}: {
  tabTitle: string;
  currentAmountOfEntries: number;
}) => {
  const [entry, setEntry] = useState<Entry>({
    createdAt: "",
    comments: [],
    id: 0,
    rating: "",
    recommended: false,
    size: 0,
    title: "",
    updatedAt: "",
    url: "",
  });

  const { register, handleSubmit, setValue } = useForm();

  const onSubmit = (data: any) => {
    const toSubmit = {
      ...data,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      id: currentAmountOfEntries,
    };

    if (data.comments) {
      toSubmit.comments = data.comments.map((comm: string) => ({
        comment: comm,
      }));
    } else {
      toSubmit.comments = [];
    }

    const db = getDatabase();
    set(ref(db, `content/${tabTitle}/${currentAmountOfEntries}`), toSubmit);
  };

  const onAddComment = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    const withNewComment = [...entry.comments, { comment: "" }];

    setEntry({
      ...entry,
      comments: withNewComment,
    });
  };

  const onRemoveComment = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number
  ) => {
    e.preventDefault();

    const withoutComment = entry.comments.filter((_, i) => i !== id);

    setValue(
      "comments",
      withoutComment.map((comment) => comment.comment)
    );

    setEntry({
      ...entry,
      comments: withoutComment,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <h3>Adding new entry for {tabTitle}</h3>
      <span>title</span>
      <div className={styles.editFormDiv}>
        <input defaultValue={entry.title} {...register("title")} />
      </div>
      <span>url</span>
      <div className={styles.editFormDiv}>
        <input defaultValue={entry.url} {...register("url")} />
      </div>
      <span>size</span>
      <div className={styles.editFormDiv}>
        <input defaultValue={entry.size} {...register("size")} />
      </div>

      <span>comments</span>

      {entry.comments?.map((comment, idx) => (
        <div className={styles.editFormDiv} key={comment.comment + idx}>
          <input
            defaultValue={comment.comment}
            {...register(`comments.${idx}`)}
          />
          <button onClick={(e) => onRemoveComment(e, idx)}>remove</button>
        </div>
      ))}
      <button onClick={onAddComment}>add comment</button>

      <span>Rating</span>

      <div className={styles.editFormDiv}>
        <input defaultValue={entry.rating} {...register("rating")} />
      </div>

      <button type="submit">Create!</button>
    </form>
  );
};

export default AdminNewForm;
